import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Copy button component, copies text to the clipboard if the browser capability exists.
 *
 * @param {*} props Properties passed to the component.
 *                  text: text to be copied to clipboard
 * @returns The JSX necessary to render the element.
 */

const CopyButton = (props) => {
  const [copyMessage, setCopyMessage] = useState(null);
  const [copyMessageClass, setCopyMessageClass] = useState(null);

  /** Clears out the message after 2 seconds. */
  useEffect(() => {
    if (copyMessage || copyMessageClass) {
      setTimeout(() => {
        setCopyMessage(null);
        setCopyMessageClass(null);
      }, 2000);
    }
  }, [copyMessage, copyMessageClass]);

  const canUseClipboard = !!navigator?.clipboard;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.text).then(
      () => {
        setCopyMessage("Copied");
        setCopyMessageClass("text-success");
      },
      () => {
        setCopyMessage("Copy Failed");
        setCopyMessageClass("text-danger");
      }
    );
  };

  return (
    canUseClipboard && (
      <>
        <span
          className="icon-clone qtr-margin-left qtr-margin-right link"
          onClick={copyToClipboard}
          title={props.helpText || "Click to copy"}
        />
        <span className={copyMessageClass}>{copyMessage}</span>
      </>
    )
  );
};

CopyButton.propTypes = {
  text: PropTypes.string,
  helpText: PropTypes.string,
};

export default CopyButton;
