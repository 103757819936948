import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import PropType from "prop-types";

const ErrorFallback = ({ error, noHome }) => {
  const errorText = useMemo(() => {
    const asString = JSON.stringify(error);
    if (asString.length > 0 && asString !== "{}") return asString;
    return "";
  }, [error]);

  const isHome = window.location.pathname === "/v1/home";

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      data-testid="ErrorFallback"
    >
      <div>
        <div>
          <h1>
            <span className="icon-exclamation-triangle base-margin-right" />
            Unexpected Client Runtime Error
          </h1>
          <p>
            Solis has run into an unexpected client-side error. Please navigate
            to another page and/or refresh the website.
          </p>
        </div>
        {errorText ? (
          <div>
            <h3>Error Data</h3>
            <pre>{errorText}</pre>
          </div>
        ) : null}
        <div>
          {!isHome && !noHome ? (
            <Link to="/v1/home" className="btn">
              Go Home
            </Link>
          ) : null}
          <button className="btn" onClick={() => window.location.reload()}>
            Refresh Page
          </button>
        </div>
      </div>
    </div>
  );
};

ErrorFallback.propTypes = {
  error: PropType.object,
  noHome: PropType.bool,
};

export default ErrorFallback;
