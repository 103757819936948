import PropTypes from "prop-types";
import React from "react";

/**
 * A spinner component used to indicate that the component is loading.
 *
 * @param {*} props Properties to pass to the component.
 * @returns JSX to render the component.
 */
const Spinner = ({ size }) => {
  return (
    <div
      className={`loader loader--${size}`}
      aria-label="Loading, please wait..."
    >
      <div className="wrapper">
        <div data-testid="loader-wheel" className="wheel"></div>
      </div>
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default Spinner;
