import React from "react";
import Button from "../buttons/Button.jsx";
import PropTypes from "prop-types";

const Modal = (props) => {
  return (
    <div
      className={`modal ${props.type} ${
        props.active || props.open ? "" : "hide"
      }`}
      data-testid="Modal"
    >
      <div className={`modal__dialog ${props.modalSize}`}>
        <div className="modal__content">
          <a className="modal__close" onClick={props.close}>
            <span className="icon-close"></span>
          </a>
          <div className="modal__header">
            <div className={`modal__title text-${props.textType}`}>
              {props.title}
            </div>
          </div>
          <div className="modal__body">
            <p className="subtitle">{props.subtitle}</p>
          </div>
          <div
            style={{
              maxHeight: "calc(100vh - 15rem)",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {props.children}
          </div>
          <div className="modal__footer">
            {!props.noButton ? (
              <Button type={`btn btn--${props.textType}`} action={props.action}>
                {props.btnText ? props.btnText : "Delete"}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  action: PropTypes.func,
  active: PropTypes.bool,
  btnText: PropTypes.string,
  children: PropTypes.node,
  close: PropTypes.func,
  noButton: PropTypes.bool,
  open: PropTypes.bool,
  textType: PropTypes.string,
  title: PropTypes.node,
  type: PropTypes.string,
  subtitle: PropTypes.string,
  modalSize: PropTypes.string,
};

export default Modal;
