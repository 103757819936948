import { useContext } from "react";
import { APIErrorContext } from "../../context/APIErrorContext";

/**
 * Wrapper around APIErrorContext methods and values.
 *
 * @returns Object containing access to APIErrorContext methods and values.
 */
const useAPIError = () => {
  const { error, addError, removeError } = useContext(APIErrorContext);
  return { error, addError, removeError };
};

export default useAPIError;
