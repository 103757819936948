import { UserContext } from "../../../context/UserContext";
import { Link } from "react-router-dom";
import React from "react";
import { useEffect } from "react";

/**
 * Header displays relevant links for a user as well as the name of
 * the product.
 *
 * @returns JSX to render the component.
 */
const Header = () => {
  /** The user in context */
  const user = React.useContext(UserContext)[0];

  /** The state of the user dropdown */
  const [select, setSelect] = React.useState(false);

  /** The state of the theme */
  const [theme, setTheme] = React.useState(
    window.localStorage["cui-theme"] || "default"
  );

  /** Whether the dropdown is selected or not */
  const toggleSelect = () => {
    setSelect(!select);
  };

  useEffect(() => {
    window.localStorage.setItem("cui-theme", theme);
    document.querySelector("body").setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <nav className="header" role="navigation">
      <div className="container-fluid">
        <div className="header-panels">
          <div className="header-panel hidden-md-down">
            <a
              className="header__logo"
              href="http://www.cisco.com"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon-cisco"></span>
            </a>
            <h1 className="header__title">
              <span>Solis: Application Security Test Orchestration</span>
            </h1>
          </div>
          <div className="header-panel header-panel--center base-margin-left base-margin-right hidden-lg-up">
            <a
              className="header__logo"
              href="http://www.cisco.com"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon-cisco"></span>
            </a>
          </div>
          <div className="header-panel header-panel--right hidden-md-down">
            <div className="base-margin-right">
              <Link to="/v1/home">
                <span
                  className="icon-home icon-size-14"
                  title="Home"
                  style={{ marginTop: "4px" }}
                />
              </Link>
            </div>
            <a
              href="https://cisco.sharepoint.com/sites/Solis"
              target="_blank"
              rel="noreferrer"
              className="header-item"
            >
              <blockquote
                className="col blockquote--primary"
                style={{ marginTop: "17px" }}
              >
                <span className="subheader text-size-14">Solis Sharepoint</span>
              </blockquote>
            </a>
            <a
              href="https://cisco.sharepoint.com/sites/SecurityandTrustOrganization"
              target="_blank"
              rel="noreferrer"
              className="header-item"
            >
              <blockquote
                className="col blockquote--primary"
                style={{ marginTop: "17px" }}
              >
                <span className="subheader text-size-14">S&amp;TO</span>
              </blockquote>
            </a>
            <a
              href="https://cisco.sharepoint.com/sites/ONEx"
              target="_blank"
              rel="noreferrer"
              className="header-item"
            >
              <blockquote
                className="col blockquote--primary"
                style={{ marginTop: "17px" }}
              >
                <span className="subheader text-size-14">ONEx</span>
              </blockquote>
            </a>
            <a
              href="https://www.cisco.com"
              target="_blank"
              rel="noreferrer"
              className="header-item"
            >
              <blockquote
                className="col blockquote--primary"
                style={{ marginTop: "17px" }}
              >
                <span className="subheader text-size-14">cisco.com</span>
              </blockquote>
            </a>
            {user && user.first_name && user.last_name ? (
              <div
                className={`btn btn--circle btn--ghost header-item dropdown dropdown--left ${
                  select ? "active" : null
                }`}
              >
                <div
                  style={{ marginLeft: "3px", marginTop: "6px" }}
                  onClick={toggleSelect}
                >
                  {user.first_name.charAt(0)}
                  {user.last_name.charAt(0)}
                </div>
                <div
                  className="dropdown__menu"
                  style={{ height: "200px", width: "220px" }}
                >
                  <div className="text-center">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "9px",
                        marginLeft: "26px",
                      }}
                    >
                      <img
                        className="qtr-margin-right image-size-36 base-margin-left"
                        src={`https://wwwin.cisco.com/dir/photo/std/${user.cec_id}.jpg`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = undefined; // prevents looping
                          currentTarget.src = `${process.env.PUBLIC_URL}/images/no-user-photo.jpg`;
                        }}
                        style={{
                          objectFit: "cover",
                          borderRadius: "100%",
                          objectPosition: "0% 17%",
                        }}
                      />
                      <span
                        className="base-margin-left"
                        style={{ fontSize: "100%", fontWeight: "600" }}
                      >
                        {user.first_name} {user.last_name}
                      </span>
                    </div>
                  </div>
                  <div className="col text-center base-margin-bottom">
                    <div
                      style={{
                        marginTop: "9px",
                      }}
                    >
                      {user.cec_id}
                    </div>
                  </div>
                  <div className="base-padding">
                    <Link to="/v1/settings">
                      <span className="icon-size-18 icon-profile-settings half-padding-right"></span>
                      Settings
                    </Link>
                  </div>
                  <div className="text-center subheader">Theme</div>
                  <div className="btn-group base-margin-bottom">
                    <div
                      className={`btn btn--ghost ${
                        theme === "default" ? "selected" : undefined
                      }`}
                      onClick={() => {
                        setTheme("default");
                        toggleSelect();
                      }}
                    >
                      Light
                    </div>
                    <div
                      className={`btn btn--ghost ${
                        theme === "dark" ? "selected" : undefined
                      }`}
                      onClick={() => {
                        setTheme("dark");
                        toggleSelect();
                      }}
                    >
                      Dark
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`btn btn--circle btn--ghost header-item dropdown dropdown--left ${
                  select ? "active" : null
                }`}
              >
                <div
                  style={{ marginLeft: "3px", marginTop: "6px" }}
                  onClick={toggleSelect}
                  icon="icon-contact"
                ></div>
                <div className="dropdown__menu" style={{ height: "50px" }}>
                  <div className="col text-center">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "9px",
                      }}
                    >
                      <span
                        className="base-margin-left"
                        style={{ fontSize: "100%", fontWeight: "600" }}
                      >
                        {"No user information found"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
