import React, { useEffect, useRef } from "react";
import useAPIError from "../../hooks/useAPIError/useAPIError";
import Modal from "../../components/primitives/modal/Modal";
import Button from "../../components/primitives/buttons/Button";
import CopyButton from "../../components/primitives/copyButton/CopyButton";
import { logApiError } from "../../api/api.js";
import { SessionContext } from "../../context/SessionContext";

/**
 * API Error Notification passing error criteria as children to a modal primitive.
 * Users can access the SOLIS Webx chat is a 500 is encountered.
 *
 * @returns JSX to render the API Error Notification.
 */
const APIErrorNotification = () => {
  const { sessionExpired } = React.useContext(SessionContext);
  const { error, removeError } = useAPIError();
  const mount = useRef(true);
  const solisTeamChatroom = process.env.REACT_APP_SUPPORT_ROOM_URL;

  /**
   * Determines whether a returned error is a unexpected browser error.
   *
   * @param {*} error An error that triggered this component rendering.
   * @returns true if the error is a browser error false otherwise.
   */
  const isErrorBrowserError =
    error?.message &&
    ((typeof error?.message === "string" &&
      error?.message?.includes("Network Error")) ||
      (typeof error?.message?.message === "string" &&
        error?.message?.message?.includes("Network Error")) ||
      error?.message?.response?.status === 0 ||
      (typeof error?.message?.code === "string" &&
        error?.message?.code?.includes("ERR_NETWORK")));

  /** Removes the error from the screen. */
  const handleSubmit = () => {
    if (sessionExpired) {
      console.log("Session expired");
      window.location.reload();
    } else if (isErrorBrowserError) {
      console.error("Unexpected Browser Error");
      window.location.reload();
    }
    removeError();
  };

  /**
   * Removes error state, closes notification, and then sends
   * user to Solis Webex support room.
   */
  const notifySolis = () => {
    removeError();
    window.open(solisTeamChatroom, "_blank");
  };

  /** When the alert mounts, log error details in the console. */
  useEffect(() => {
    if (error) {
      logApiError(error);
    }
    return () => {
      mount.current = false;
    };
  }, [error]);

  /**
   * Determines whether a returned error has a 5XX status.
   *
   * @param {*} error An error that triggered this component rendering.
   * @returns true if the error is 5XX false otherwise.
   */
  const isError5XX = (error) => {
    return parseInt(error?.message?.response?.status) >= 500;
  };

  return (
    <Modal
      open={!!error}
      type={"modal--med"}
      textType={"danger"}
      close={handleSubmit}
      title={
        sessionExpired
          ? "Your session has expired"
          : isErrorBrowserError
          ? "Unexpected Browser Error"
          : "Encountered an error with your request"
      }
      subtitle={""}
      action={handleSubmit}
      btnText={isErrorBrowserError ? "Refresh" : "Close"}
    >
      {isErrorBrowserError ? (
        <>
          <p>
            <b>Code:</b> {error.message?.code}
          </p>
          <p>
            <b>Description:</b> {error.message?.message}
          </p>
          Unexpected Browser Error occurred, check your network connection and
          refresh the page.
        </>
      ) : error?.message?.response ? (
        <React.Fragment>
          <div>
            <p>
              <b>Status:</b> {error.message?.response?.status}
            </p>
          </div>
          <div>
            <p>
              <b>Type:</b> {error.message?.response?.data?.errorType}
            </p>
          </div>
          <div>
            <b>Description:</b> <p>{error.message?.response?.data?.message}</p>
          </div>
          <div>
            <b>Request ID:</b>{" "}
            <span>
              {error.message?.response?.headers?.["x-amzn-requestid"]}
            </span>
            <CopyButton
              text={error.message?.response?.headers?.["x-amzn-requestid"]}
            />
          </div>
          <div className="text-muted text-size-12">
            Provide this ID when reaching out to support
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div>
            <b>Description:</b>
            <p>
              {sessionExpired
                ? "Session timed out! User must re-authenticate."
                : error?.message || "Unknown Error"}
            </p>
          </div>
        </React.Fragment>
      )}
      {isError5XX(error) ? (
        <div
          style={{ position: "absolute", right: "115px", marginTop: "16px" }}
        >
          <Button type={"btn btn--primary"} action={notifySolis}>
            Report Error
          </Button>
        </div>
      ) : null}
    </Modal>
  );
};

export default APIErrorNotification;
