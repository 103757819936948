import axios from "axios";
import Fallback from "../components/primitives/fallback/Fallback";
import Layout from "../components/layout-v1/Layout";
import PrivateRoute from "./PrivateRoute";
import PropTypes from "prop-types";
import React from "react";
import { AppContext } from "../context/AppContext";
import { getAfterLoginGoTo, setOAuthState } from "../utils/utils";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { Switch } from "react-router-dom";
import { uiVersionPath } from "../utils/constants";

const HomeV1 = React.lazy(() => import("../views/home-v1/Home"));
const Apps = React.lazy(() => import("../views/apps/Apps"));
const App = React.lazy(() => import("../views/app/App"));
const Target = React.lazy(() => import("../views/target/Target"));
const Results = React.lazy(() => import("../views/results/Results"));
const Finding = React.lazy(() => import("../views/finding/Finding"));
const Support = React.lazy(() => import("../views/support/Support"));
const OAuthCallbackPage = React.lazy(() =>
  import("../views/oAuthCallback/OAuthCallback")
);
const LoginErrorPage = React.lazy(() =>
  import("../views/loginError/LoginError")
);
const APIDocsV1 = React.lazy(() => import("../views/apiDocs-v1/ApiDocs"));
const Stats = React.lazy(() => import("../views/stats/Stats"));
const AdminV1 = React.lazy(() => import("../views/admin-v1/Admin"));
const ExternalTicket = React.lazy(() =>
  import("../views/externalTicket/ExternalTicket")
);
const ScanConfig = React.lazy(() => import("../views/scanConfig/ScanConfig"));
const TicketingRules = React.lazy(() =>
  import("../views/ticketingRules/TicketingRules")
);
const TicketingRule = React.lazy(() =>
  import("../views/ticketingRule/TicketingRule")
);
const Settings = React.lazy(() => import("../views/settings/Settings"));
/**
 * Creates a global axios default for the baseURL
 * for each API request and better hook notifications.
 */
axios.defaults.baseURL = process.env.REACT_APP_SOLIS_API_BASE_URL;

/**
 * Script to redirect the page to the OAuth provider with
 * necessary query parameters set, namely the OAuth Client ID,
 * the OAuth URL, redirect for after succcessful challenges and
 * the OAuth scope.
 */
function login() {
  // The OAuth Client ID (set in PMT).
  const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
  // The OAuth provider base URL.
  const authorizationUrlBase = process.env.REACT_APP_AUTHORIZATION_URL_BASE;
  // The redirect URI, namely the URL for the OAuthCallbackPage.
  const redirectUri = window.location.origin + "/oauth-callback";
  // OAuth scope.
  const scope = process.env.REACT_APP_OAUTH_SCOPE;
  const state = crypto.randomUUID();

  const url = new URL(authorizationUrlBase);

  url.searchParams.append("response_type", "code");
  url.searchParams.append("redirect_uri", redirectUri);
  url.searchParams.append("client_id", clientId);
  url.searchParams.append("scope", scope);
  url.searchParams.append("state", state);

  setOAuthState(state);

  window.location.assign(url.toString());
}

/**
 * Routes for the application, what to render based on
 * the URL.
 *
 * @param {*} props Properties passed to the React component.
 */
export default function Routes(props) {
  const state = React.useContext(AppContext)[0];
  const { sessionExpired } = React.useContext(SessionContext);

  return !sessionExpired ? (
    <React.Suspense
      fallback={
        <Layout>
          <Fallback />
        </Layout>
      }
    >
      <Switch>
        <Route path="/login" render={() => login()} />
        <Route
          path="/oauth-callback"
          render={() => (
            <OAuthCallbackPage
              setAccessToken={props.setAccessToken}
              setLoginError={props.setLoginError}
              loggedIn={props.loggedIn}
            />
          )}
        />
        <PrivateRoute path="/v1/home">
          <HomeV1 />
        </PrivateRoute>
        <PrivateRoute exact path="/v1/apps">
          <Apps />
        </PrivateRoute>
        <PrivateRoute exact path="/v1/apps/new">
          <Apps />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/v1/apps/:appId/targets/:targetId/external-tickets/:externalTicketId"
        >
          <ExternalTicket />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/v1/apps/:appId/targets/:targetId/finding/:findingId"
        >
          <Finding />
        </PrivateRoute>
        <PrivateRoute path={`${uiVersionPath}/apps/:appId/targets/:targetId`}>
          {/* Includes the following subroutes:
          /info
          /scan-configs
          /schedules
          /reports */}
          <Target />
        </PrivateRoute>
        <PrivateRoute exact path="/v1/apps/:appId/scan-configs/:scanConfigId">
          <ScanConfig />
        </PrivateRoute>
        <PrivateRoute path={`${uiVersionPath}/apps/:appId`}>
          {/* Includes the following subroutes:
          /info
          /targets
          /scan-configs
          /secrets
          /files
          /notifications */}
          <App />
        </PrivateRoute>
        <PrivateRoute exact path="/v1/ticketing-rules/:ticketingRuleId">
          <TicketingRule />
        </PrivateRoute>
        <PrivateRoute exact path="/v1/ticketing-rules">
          <TicketingRules />
        </PrivateRoute>
        <PrivateRoute path="/v1/settings">
          <Settings />
        </PrivateRoute>
        <PrivateRoute exact path="/v1/support">
          <Support />
        </PrivateRoute>
        <PrivateRoute exact path="/v1/help">
          <Support />
        </PrivateRoute>
        <PrivateRoute path="/v1/api-docs">
          <APIDocsV1 />
        </PrivateRoute>
        <PrivateRoute path="/v1/admin">
          <AdminV1 />
        </PrivateRoute>
        <PrivateRoute path="/v1/admin/active-scans">
          <AdminV1 />
        </PrivateRoute>
        <PrivateRoute path="/v1/admin/access-lists">
          <AdminV1 />
        </PrivateRoute>
        <PrivateRoute path="/v1/results">
          <Results />
        </PrivateRoute>
        <PrivateRoute path="/stats">
          <Stats />
        </PrivateRoute>
        <PrivateRoute path="/v1/stats">
          <Stats />
        </PrivateRoute>
        <Route
          path="/login-error"
          render={() => <LoginErrorPage loginError={props.loginError} />}
        />
        {state.ready ? (
          <Redirect from="/oauth-callback" to={getAfterLoginGoTo()} />
        ) : null}
        <Redirect from="/" to="/v1/home" />
      </Switch>
    </React.Suspense>
  ) : null;
}

Routes.propTypes = {
  setAccessToken: PropTypes.func,
  setLoginError: PropTypes.func,
  loggedIn: PropTypes.bool,
  loginFailed: PropTypes.bool,
  loginError: PropTypes.object,
};
