import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import PropTypes from "prop-types";
import { setAfterLoginGoTo } from "../utils/utils";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ children, ...rest }) => {
  const state = useContext(AppContext)[0];
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return state.ready ? (
          React.Children.map(children, (child) => {
            return React.cloneElement(child, { ...rest });
          })
        ) : (
          <React.Fragment>
            {(() => {
              setAfterLoginGoTo(`${location.pathname}${location.search}`);
            })()}
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default PrivateRoute;
