import PropTypes from "prop-types";
import React from "react";
import useAPIError from "../hooks/useAPIError/useAPIError";
import { AppContext } from "./AppContext";
import { abortController } from "../api/api";
import { getExpiration } from "../utils/utils";
import { OneHourInMillis } from "../utils/constants";
import { OneSecondInMillis } from "../utils/constants";
import { setExpiration } from "../utils/utils";
import { RefreshToken } from "../api/api";

/**
 * Session context stores the expiration state of the session and a function
 * to expire the session.
 */
const SessionContext = React.createContext({
  sessionExpired: false,
  makeSessionExpired: () => {},
});

/**
 * Provider for managing the session, refreshes the token and kills
 * the app after inactivity.
 *
 * @param {*} children Child components passed in.
 * @returns JSX to handle the session.
 */
const SessionProvider = ({ children }) => {
  /** Whether the session provider has mounted or not. */
  const mount = React.useRef(true);
  /** The app context containing the token and ready state. */
  const [state, setState] = React.useContext(AppContext);
  /**
   * A variable indicating if the error is the result of session expiration.
   * In this case removing the error is going to take the user back to login.
   */
  const [sessionExpired, setSessionExpired] = React.useState(false);
  /** Function to add an error to the screen via a modal. */
  const { addError } = useAPIError();
  /** Sets the session expired variable as true. */
  const makeSessionExpired = () => {
    abortController.abort();
    mount.current && setSessionExpired(true);
    addError("Session time out! User must re-authenticate.");
  };
  /**
   * Use effect that tracks the session. From login time, this will be 8
   * hours. In other words, Solis will continue to refresh the JWT every hour
   * for 8 hours and then give the user the boot. If there larger CloudSSO
   * session has run over they'll have to go through duo MFA again, otherwise
   * CloudSSO will refresh the user's credentials when the page reloads.
   */
  React.useEffect(() => {
    const session = setTimeout(() => {
      makeSessionExpired();
    }, OneHourInMillis * 8);
    return () => {
      clearTimeout(session);
      mount.current = false;
    };
  }, []);
  /**
   * Ref to keep track of the refresh timeout,
   * so that we don't have multiple timouts causing refresh failures.
   */
  const refreshTimeout = React.useRef();
  /**
   * Clears out the current timeout to refresh the token and
   * creates a new timeout to refresh the token. The timeout
   * runs ten seconds before the current token is set to expire.
   */
  const createRefresh = () => {
    clearTimeout(refreshTimeout.current);
    if (!sessionExpired) {
      refreshTimeout.current = setTimeout(async () => {
        try {
          const refreshResponse = await RefreshToken();
          let token = refreshResponse.data.token;
          let cecId = refreshResponse.data.cec_id;
          let new_user = refreshResponse.data.new_user;
          let newExpiration = refreshResponse.data.expiration * 1000;
          setState({
            token: token,
            cecId: cecId,
            new_user: new_user,
            ready: true,
          });
          setExpiration(newExpiration);
        } catch (err) {
          makeSessionExpired();
          addError(err.message);
        }
      }, getExpiration() - Date.now() - OneSecondInMillis * 30 || OneHourInMillis / 2);
    }
  };
  /**
   * Use effect that tracks the refresh timeout. Creates and clears the timeout
   * that refreshes the token. This gets retriggered whenever a previous refresh succeeds
   * (and the token has changed) or when a session is expired.
   */
  React.useEffect(() => {
    state.ready && createRefresh();
    sessionExpired && clearTimeout(refreshTimeout.current);
    () => {
      clearTimeout(refreshTimeout.current);
    };
  }, [state, sessionExpired]);

  return (
    <SessionContext.Provider
      value={{
        sessionExpired: sessionExpired,
        makeSessionExpired: makeSessionExpired,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.node,
};

export { SessionContext, SessionProvider };
