import PropTypes from "prop-types";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";

/** The default state for the app, before login. */
const defaultState = {
  token: "",
  ready: false,
  new_user: false,
  cecId: "",
};

/** Context for the app, session state an user ID. */
const AppContext = React.createContext([{}, () => {}]);

/**
 * Top level component that provides state and setState hook
 * to children that are passed to the provider. This makes available the token,
 * ready state, and applications array to all components.
 *
 * @param {*} props Optional preconditions.
 */
const AppProvider = (props) => {
  /** Offer state management of the state with useState hook. */
  const [state, setState] = useState(props.state || defaultState);

  /** Defines a callback method which wraps around setState. */
  const setContext = useCallback(
    (updates) => {
      setState({ ...state, ...updates });
    },
    [state, setState]
  );

  /** Defines the context value to pass to the provider. */
  const getContextValue = useCallback(() => [state, setContext], [
    state,
    setContext,
  ]);

  return (
    <AppContext.Provider value={getContextValue()}>
      {props.children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  state: PropTypes.object,
  children: PropTypes.node,
};

export { AppContext, AppProvider };
