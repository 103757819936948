import React from "react";
import Spinner from "../spinner/Spinner";

/**
 * Fallback renders a loader wheel in the center of the page and is used when
 * lazy loading view components.
 *
 * @returns JSX to render a loader wheel.
 */
const Fallback = () => {
  return (
    <div
      className={"container-dashboard flex flex-middle"}
      style={{
        height: "100vh",
        width: "100%",
        position: "absolute",
        left: "0px",
        top: "0px",
        marginLeft: "0px",
        paddingLeft: "0px",
      }}
    >
      <div className={"dashboard-content"}>
        <Spinner size="large" />
      </div>
    </div>
  );
};

export default Fallback;
