import PropTypes from "prop-types";
import React from "react";
import "../../../styles/button.scss";

/**
 * A customizable button.
 *
 * @param {*} props Properties to pass to the component.
 * @returns JSX to render the button.
 */
const Button = (props) => {
  return (
    <button
      className={`${props.type}`}
      onClick={props.action}
      disabled={props.disabled ? true : false}
      style={props.style}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  action: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
};

export default Button;
