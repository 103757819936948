import axios from "axios";
import { abortController } from "./api";

/**
 * Session timeout method. Throws an error indicating that
 * the user should re-authenticate.
 *
 * @throws Session timeout error.
 */
const sessionTimeout = () => {
  abortController.abort();
  console.log("Session expired");
  throw new Error("Session timed out! User must re-authenticate!");
};

/** Creates a custom axios instance just for refreshing the token. */
const refreshTokenAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SOLIS_API_BASE_URL,
});

/**
 * Interceptor for error responses to, checks to see
 * if the cache is less than a day old and has a successful
 * response on 401. This is useful if two requests with the
 * same cookie are sent in rapid succession.
 *
 * @param {*} error The error response.
 * @returns A response.
 */
const errorResponseInterceptor = (error) => {
  sessionTimeout(error);
};

refreshTokenAxiosInstance.interceptors.response.use(
  null,
  errorResponseInterceptor
);

export default refreshTokenAxiosInstance;
