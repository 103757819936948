import React from "react";
import PropType from "prop-types";
import ErrorFallback from "./ErrorFallback";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorData: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorData: error };
  }

  // Trigger error logging logic here for DAST-2062
  // componentDidCatch(error, info) {}

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorFallback
          error={this.state.errorData}
          noHome={this.props.noHome}
        />
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropType.node,
  noHome: PropType.bool,
};

export default ErrorBoundary;
