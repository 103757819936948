import React from "react";
import PropTypes from "prop-types";
import { AppContext } from "./AppContext";
import { GetUserProfile } from "../api/api";

/** Context for the current user document. */
const UserContext = React.createContext([{}, () => {}, () => {}]);

/**
 * Top level component that provides state and setState hook
 * to children that are passed to the provider. This makes available the token,
 * ready state, and applications array to all components.
 *
 */
const UserProvider = (props) => {
  /** The AppContext, contains the token used for requests. */
  const contxt = React.useContext(AppContext)[0];
  /** The user document stored in context. */
  const [userState, setUserState] = React.useState(
    props.state || {
      _id: "",
      cec_id: "",
      created_at: "",
      first_name: "",
      last_name: "",
      global_role: "",
      roles: [],
      last_login: "",
      personal_access_tokens: [],
    }
  );

  /** Refreshes the user on demand. */
  const refreshUser = React.useCallback(async () => {
    try {
      const user = await GetUserProfile(contxt.token);
      setUserState(user);
    } catch (error) {
      console.log(error);
      // If this fails, we should probably reload the page and re-run the authentication
      // process so that the user does not end up in some strange state where they
      // have no user document stored in context.
      window.location.reload();
    }
  }, [contxt, userState]);

  return (
    <UserContext.Provider value={[userState, setUserState, refreshUser]}>
      {props.children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  state: PropTypes.object,
  children: PropTypes.node,
};

export { UserContext, UserProvider };
