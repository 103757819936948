import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";

/**
 * Context to manage alerts so that they don't need to be cleared out
 * every time the display changes.
 */
const AlertContext = React.createContext({
  welcome: [true, () => {}],
});

/**
 * Provider component for alert context.
 *
 * @param props Properties to pass to the provider these
 *              should be used for testing.
 * @returns JSX to render the provider.
 */
const AlertProvider = (props) => {
  /** Display the welcome alert. */
  const welcome = useState(true);

  /** Context value, idea is to expand this as other alerts may be added. */
  const contextVal = {
    welcome: welcome,
  };

  return (
    <AlertContext.Provider value={contextVal}>
      {props.children}
    </AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.node,
};

export { AlertContext, AlertProvider };
