import Fallback from "../primitives/fallback/Fallback";
import Header from "./header/Header";
import PropTypes from "prop-types";
import React from "react";
import { AppContext } from "../../context/AppContext";
import "../../styles/layout.scss";
import "../../styles/sidebar.scss";
import ErrorBoundary from "../primitives/errorBoundary/ErrorBoundary";

/**
 * Layout to wrap Solis content in, namely the header and a container.
 *
 * @param {React.Component} children Any children components of the layout.
 * @returns JSX to render the component.
 */
const Layout = ({ children }) => {
  const state = React.useContext(AppContext)[0];
  return (
    <div className="solis" data-testid="Layout">
      {state.ready ? (
        <>
          <ErrorBoundary>
            <Header />
          </ErrorBoundary>
          <div
            className="container-fluid row"
            style={{
              height: "100%",
              width: "100%",
              padding: 0,
              paddingTop: "3rem",
            }}
          >
            {React.Children.map(children, (child) => (
              <ErrorBoundary>{child}</ErrorBoundary>
            ))}
          </div>
        </>
      ) : (
        <Fallback />
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
