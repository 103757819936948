import React from "react";
import PropTypes from "prop-types";

/** Context for API Errors. */
const APIErrorContext = React.createContext({
  error: null,
  addError: () => {},
  removeError: () => {},
});

/**
 * Top level component that provides state and setState hook
 * to children that are passed to the provider. This makes api errors available to
 * all children passed in the application. Primarily using
 * to display an error modal alert when an error occurs.
 *
 * Messages (strings) and the status code will be saved in context.
 * The functions to add and remove (clear) the error are also exposed
 * in context through the provider.
 */
const APIErrorProvider = (props) => {
  /** The error we intend to store. */
  const [error, setError] = React.useState(props.initError || false);

  /** Sets the error as false. Removing it from the screen. */
  const removeError = () => {
    setError(false);
  };

  /** Adds an error, rendering an error modal on screen. */
  const addError = (message) => setError({ message });

  /** The value to return in the context. */
  const contextValue = {
    error,
    addError: React.useCallback((message) => addError(message), []),
    removeError: React.useCallback(() => removeError(), []),
  };

  return (
    <APIErrorContext.Provider value={contextValue}>
      {props.children}
    </APIErrorContext.Provider>
  );
};

APIErrorProvider.propTypes = {
  children: PropTypes.node,
  initError: PropTypes.instanceOf(Error),
};

export { APIErrorContext, APIErrorProvider };
