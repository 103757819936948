import APIErrorNotification from "./components/apiErrorNotification/APIErrorNotification";
import React from "react";
import Routes from "./routes/Routes";
import { AlertProvider } from "./context/AlertContext";
import { APIErrorProvider } from "./context/APIErrorContext";
import { AppProvider } from "./context/AppContext";
import { BrowserRouter as Router } from "react-router-dom";
import { SessionProvider } from "./context/SessionContext";
import { UserProvider } from "./context/UserContext";
import { useState } from "react";
import "lodash";
import "./styles/pagination.scss";
import "./styles/severity.scss";
import ErrorBoundary from "./components/primitives/errorBoundary/ErrorBoundary";

/**
 * App serves as the entrypoint into the application. From this component
 * routes are established and the client side scripts begin.
 */
const App = () => {
  const [userAuth, setUserAuth] = useState({
    accessToken: null,
    loggedIn: false,
    loginFailed: false,
    loginError: null,
  });

  /** Sets the state of the access token if a token is not null. */
  const setAccessToken = (token) => {
    if (token) {
      setUserAuth({
        ...userAuth,
        accessToken: token["token"],
        expiration: token["expiration"],
        loggedIn: true,
      });
    }
  };

  /** Sets the state of the application when login fails. */
  const setLoginError = (error) => {
    if (error) {
      setUserAuth({ loggedIn: false, loginFailed: true, loginError: error });
    }
  };

  return (
    <ErrorBoundary noHome={true}>
      <AppProvider>
        <UserProvider>
          <AlertProvider>
            <APIErrorProvider>
              <SessionProvider>
                <Router>
                  <Routes
                    {...userAuth}
                    setAccessToken={setAccessToken}
                    setLoginError={setLoginError}
                  />
                </Router>
                <APIErrorNotification />
              </SessionProvider>
            </APIErrorProvider>
          </AlertProvider>
        </UserProvider>
      </AppProvider>
    </ErrorBoundary>
  );
};

export default App;
